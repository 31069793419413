$stava-rex-bg: #ff9;
$stava-rex-text: #47b;

$spellright-bg: #def;
$spellright-text: #e44;

$verityspell-bg: #f4f0ec;
$verityspell-text: #000;

$stavlet-bg: #fee;
$stavlet-text: #e43;

$schreibrex-bg: #fef;
$schreibrex-text: #849;

:root {
  --color-stava-rex-bg: $stava-rex-bg;
  --color-stava-rex-text: $stava-rex-text;

  --color-spellright-bg: $spellright-bg;
  --color-spellright-text: $spellright-text;

  --color-verityspell-bg: $verityspell-bg;
  --color-verityspell-text: $verityspell-text;

  --color-stavlet-bg: $stavlet-bg;
  --color-stavlet-text: $stavlet-text;

  --color-schreibrex-bg: $schreibrex-bg;
  --color-schreibrex-text: $schreibrex-text;
}

body {
  overflow: hidden;
  min-width: 270px;

  .ui.basic.buttons a.button,
  a.ui.basic.button,
  .ui.basic.buttons a.button:visited,
  a.ui.basic.button:visited {
    text-decoration: none;
    &:hover, &:active, &:focus {
      text-decoration: underline;
    }
  }
}

.ui.card, .ui.cards>.card {
  width: auto;
}

.tab-view-panel {
  .tab-view-panel-scrolling-content {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 40px);
    padding-bottom: 1em;
  }
}

.ui.top.attached.message ~ .tab-view-panel {
  max-height: calc(100vh - 90px);
}

.ui.fluid.search .ui.input {
  width: 100%;
}

.schools-results {
  text-align: left;
  .ui.list {
    margin-top: 10px;
    max-height: 200px;
    overflow-y: auto;
    &:empty {
      margin-top: 0;
    }
  }
}

.ui.selection.list > .item .ui.placeholder .paragraph:before {
  height: 10px;
}

a.ui.basic.inverted.button,
a.ui.basic.inverted.buttons .button {
  color: #eff !important;
}

.ui.basic.buttons a.button {
  padding: .78571429em 1.5em .78571429em;
  text-decoration: none;
}

.ui.form .warning.message:not(:empty) {
  display: block;
}

.ui.modal {
  &:not(.basic) {
    max-width: 320px;
  }
  &.center {
    text-align: center;
  }
  &.basic {
    h1.header {
      font-size: 2rem;
    }

    a, a:visited {
      color: #fff;
      text-decoration: underline;
      &:hover, &:active {
        text-decoration: none;
      }
    }
  }

  .header {
    
    img {
      width: 64px;
      margin: 0 auto .5em;
    }
  }

  .content {
    + .content {
      padding-top: 0 !important;
    }
    .accordion {
      text-align: left;
    }
  }

  .actions {
    overflow: hidden;
    p {
      text-align: center;
      &.logout-btn-label {
        font-size: .8rem;
        word-break: break-word;
      }
    }
    > p:not(.logout-btn-label) {
      padding-bottom: 1rem;
      margin-top: -.5rem;
    }
    + .content {
      border-top: 1px solid rgba(34,36,38,.15);
    }
    .form {
      padding-bottom: 1rem;
      .error.message:not(:empty) {
        display: block;
      }
    }
  }
  
  .search .results {
    max-height: 40vh;
    overflow-y: auto;
  }
}

.app-switcher {
  text-align: center;
  max-height: 100vh;
  overflow-y: auto;
  margin: 0 !important;
  h1 {
    font-size: 2rem;
    padding: 1rem 1rem 0;
  }
}
.app-switcher-grid {
  display: flex;
  flex-wrap: wrap;
  > a.ui.image {
    flex-shrink: 0;
    flex-basis: 128px;
    width: 128px;
    display: flex;
    flex-direction: column;
    padding: .5rem;
    .label {
      order: 2;
      // text-align: center;
    }
    img {
      order: 1;
      position: relative;
      transition: all .2s;
      top: 0;
    }
    &:hover {
      img {
        top: -.33rem;
      }
    }
  }
}

@mixin branded($background, $color) {
  .ui.modal .header.branded {
    background: $background;
    color: $color;
  }

  .tab-view-panel.branded {
    background: #f5f5f5;
    z-index: 0;
    position: relative;

    @media (prefers-color-scheme: dark) {
      background: #023;
    }

    &:after {
      position: fixed;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url(/oribi-bg-blobs-tall.svg) no-repeat center center fixed;
      opacity: .5;
      background-size: cover;
      z-index: -1;
    }
    
    h1 {
      color: $color;
      @media (prefers-color-scheme: dark) {
        color: #fff;
      }
    }

    // @media (prefers-color-scheme: dark) {
    //   $darker: darken($background, 90%);
    //   background: linear-gradient(180deg, $background 67%, $darker 100%);
    // }
  }

  &[data-host="OneNote"] {
    @include panelDarkMode(#fff, #fff, $color, $color);
  }
  &[data-host="Word"] {
    @include panelDarkMode(#edebe9, #edebe9, $color, $color);
  }
  &[data-host="Word"][data-platform="Mac"] {
    @include panelDarkMode(#ececec, #323232, $color, $background);
    &[data-app="stava-rex"] {
      @include panelDarkMode(#ececec, #323232, $color, #fff);
    }
  }
  &[data-host="Word"][data-platform="PC"] {
    @include panelDarkMode(#f3f3f3, #363636, $color, $background);
    &[data-app="stava-rex"] {
      @include panelDarkMode(#f3f3f3, #363636, $color, #fff);
    }
  }

}
@mixin panelDarkMode($light, $dark, $title, $titleDark) {
  .tab-view-panel.branded {
    background: $light;
    @media (prefers-color-scheme: dark) {
      background: $dark;
    }
    h1 {
      color: $title;
      @media (prefers-color-scheme: dark) {
        color: $titleDark;
      }
    }
  } 
}

[data-app="stava-rex"] {
  @include branded($stava-rex-bg, $stava-rex-text);
}
[data-app="spellright"] {
  @include branded($spellright-bg, $spellright-text);
}
[data-app="verityspell"] {
  @include branded($verityspell-bg, $verityspell-text);
}
[data-app="stavlet"] {
  @include branded($stavlet-bg, $stavlet-text);
}
[data-app="schreibrex"] {
  @include branded($schreibrex-bg, $schreibrex-text);
}